@font-face {
  font-family: 'SourceHanSerifSC';
  src: url("./assets/SourceHanSerifSC-Regular.eot");
  src: 
    url("./assets/SourceHanSerifSC-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/SourceHanSerifSC-Regular.woff") format("woff"),
    url("./assets/SourceHanSerifSC-Regular.ttf") format("truetype"),
    url("./assets/SourceHanSerifSC-Regular.svg#SourceHanSerifSC") format("svg");
}

html, #root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: "SourceHanSerifSC", "lucida grande", "lucida sans unicode",
                lucida, helvetica, "Hiragino Sans GB", "Microsoft YaHei",
                "WenQuanYi Micro Hei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  cursor: normal;
  user-select: none;
}

a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}
